<template>
 <div class="client-add">
    <el-dialog :title="dialogData.title" :visible.sync="dialogTableVisible" @close="resetForm" width="28%" :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <!-- <template v-for="(elem,ins) in column" >
                <els-elem :key="elem.id || elem.name || ins" :elem="elem"></els-elem>
            </template> -->
            <template v-for="(els,index) of column">
                <template v-if="els.type==='input'">
                    <el-form-item :key="els.lable" :label="els.label" :prop="keys[index]">
                        <el-input v-model="ruleForm[keys[index]]" :placeholder="els.placeholder"></el-input>
                    </el-form-item>
                </template>
                 <template v-else-if="els.type==='select'">
                    <el-form-item :key="els.lable" :label="els.label" :prop="keys[index]">
                     <el-select v-model="ruleForm[keys[index]]">
                      <el-option v-for="ele of els.cls" :key="ele.label" :label="ele.label" :value="ele.value" :placeholder="els.placeholder"></el-option>
                     </el-select>
                    </el-form-item>
                </template>
                <template v-else-if="els.type==='textarea'">
                    <el-form-item :key="els.lable" :label="els.label" :prop="keys[index]">
                        <el-input type="textarea" v-model="ruleForm[keys[index]]" :placeholder="els.placeholder"></el-input>
                    </el-form-item>
                </template>
            </template>
        </el-form>
        <slot></slot>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirm(ruleForm)">{{$t('d.confirm')}}</el-button>
            <el-button @click="resetForm">{{$t('d.cancel')}}</el-button>
        </div>
    </el-dialog>
 </div>
</template>
<script>
// import elsElem from "../els-elem/main"
export default {
    name:'',
    // components:{elsElem},
    props:{
        dialogData:{type:Object,default:()=>({})},
        column:{type:Array},
        ruleForm:Object,
        rules:Object,
    },
    data:()=>{
      return {
          dialogTableVisible:false,
          keys:[]
      }
    },
    mounted(){
       this.keys = this.getKeys(this.ruleForm);
    },
    methods:{
        getKeys(v){
         const keys = Object.keys(v);
         return keys;
        },
        confirm(ruleForm){
            console.log(ruleForm)
            this.$refs.ruleForm.validate((valid) => {
              this.dialogTableVisible = false;
                for(let key  in this.ruleForm){
                 this.ruleForm[key] = '';
              }
            })
        },
        resetForm(){
            this.dialogTableVisible = false;
            this.$refs.ruleForm.resetFields();
        }
    }
}
</script>
<style scoped lang="scss">

</style>
